import './TopNav.css'
import Logo from '../../Assets/jaquezlawofficelogo 4.svg'
import { Component } from 'react';

export default class TopNavMobile extends Component{

  render(){
    return (
      <div className='row' style={{height: '25vh', overflow: 'hidden', backgroundColor: '#03254e', color: '#fff'}}>
        <div className='col-12' style={{height: '50%'}}>
          <img src={Logo} style={{height: '100%', width: '50%', marginTop: '2.5vh', transform: 'scale(1.75)'}} />
        </div>
        <div className='col-12'>
            <div className='row' style={{height: '15vh', justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div className='col-3'>
                <h1 className='h6' style={{fontSize: '0.85rem'}}><a href='#aboutme' style={{color: 'white'}}>About Me</a></h1>
              </div>
              <div className='col-3'>
                <h1 className='h6' style={{fontSize: '0.85rem'}}><a href='#aor' style={{color: 'white'}}>Services</a></h1>
              </div>
              <div className='col-3'>
                <h1 className='h6' style={{fontSize: '0.85rem'}}><a href='#contact' style={{color: 'white'}}>Contact</a></h1>
              </div>
              <div className='col-3'>
                <h1 className='h6' style={{fontSize: '0.85rem'}}><a href='/espanol' style={{color: 'white'}}>Espanol</a></h1>
              </div>
            </div>
        </div>   
    </div> 
    );
  }
}