import { Component } from "react"
import profile from '../../Assets/jaquezLawProfile.jpg'

export default class AOR_Spanish extends Component {
    render(){
        return(
            <div className='row' id="aor">
                <div className='col-10' style={{color: '#7E8080'}}>
                    <div className='row' style={{borderBottom: '2px solid #7E8080', width: '100%'}}>
                       <h1 className='h1 text-left' style={{padding: '2vh 2vw', fontSize: 'calc((2.8 - 1) * 1.2vw + 2.5rem)', color: '#7E8080'}}>Áreas de Especialidad</h1>
                    </div>
                    <div className='row' style={{borderBottom: '2px solid #7E8080', width: '100%'}}>
                       <div className='col-4'>
                           <h1 className='h3 text-left pt-5'>Peticiones Familiares y Defensa de Deportación</h1>
                       </div>
                       <div className='col-8 pl-5'>
                           <h1 className='h6 py-5 text-left' style={{lineHeight: '2rem'}}>Si tiene familiares aquí en los Estados Unidos o en su país de origen fuera de los Estados Unidos, el abogado Jaquez puede explicar
                           cuál es la mejor opción para hacer una petición para obtener la residencia permanente o la ciudadanía para su familia. Cuando alguien es detenido por inmigración o se le da un aviso para comparecer en la corte de inmigración, el abogado Jaquez se reunirá con usted y / o
                           su familia para conocer más sobre su situación para que pueda determinar cuál es la mejor estrategia para defender sus derechos.</h1>
                       </div>
                    </div>
                    <div className='row' style={{borderBottom: '2px solid #7E8080', width: '100%'}}>
                       <div className='col-4'>
                           <h1 className='h3 text-left pt-5'>Visas de Negocios y Empleo</h1>
                       </div>
                       <div className='col-8 pl-5'>
                           <h1 className='h6 py-5 text-left' style={{lineHeight: '2rem'}}>Si usted o alguien cercano a usted ha sufrido porque fue víctima de un crimen, entonces puede ser elegible para solicitar una visa U
                           que puede otorgarle un permiso de trabajo y eventualmente residencia permanente. El abogado Jaquez también trabaja con muchas empresas en calidad de asesor comercial para tomar decisiones comerciales importantes con
                           confianza.</h1>
                       </div>
                    </div>
                    <div className='row' style={{borderBottom: '2px solid #7E8080', width: '100%'}}>
                       <div className='col-4'>
                           <h1 className='h3 text-left pt-5'>Posesión y otros Cargos Criminales</h1>
                       </div>
                       <div className='col-8 pl-5'>
                           <h1 className='h6 py-5 text-left' style={{lineHeight: '2rem'}}>Si ha sido citado por DUI, debe saber que tiene opciones. Tiene solo 10 días para solicitar una audiencia del DMV para
                           impugnar la suspensión de su licencia. Si ha sido arrestado por cualquier otro asunto criminal y quiere que alguien le dé una evaluación completa y justa de su caso, llame al abogado Jaquez.</h1>
                       </div>
                    </div>
                </div>
            </div>
        )
    }
}