import { Component } from "react"
import profile from '../../Assets/jaquezLawProfile.jpg'

export default class ContactUs extends Component {
    render(){
        return(
            <div className='row' id='contact'>
                <div className='col-10' style={{color: '#fff'}}>
                    <div className='row'>
                        <h1 className='h1 text-left pl-5' style={{padding: '2vh 2vw', fontSize: 'calc((2.8 - 1) * 1.2vw + 2.5rem)', color: '#fff'}}>Get in Touch</h1>
                    </div>
                    <div className='row'>
                        <div className='col-8'>
                            <form action="mailto:luis@jaquezlaw.com" method="post" enctype="text/plain" style={{marginLeft: '5vw'}}>
                            {/* <form action="mailto:ejg132@gmail.com" method="post" enctype="text/plain" style={{marginLeft: '5vw'}}> */}
                                
                                <div className='row'>
                                    <input className="input-group-text col-4" id="first-name" type="text" required placeholder="First name" name="First-Name" />
                                    <input className="input-group-text col-4" type="text" required placeholder="Last name" name="Last-Name" />
                                </div>

                                <div className='row' style={{marginTop: '1vh'}}>
                                    <input className="input-group-text col-8" id="eml" type="email" required placeholder="@email" name="Email" />
                                </div> 
                                
                                <div className='row' style={{marginTop: '1vh'}}>
                                    <input className="input-group-text col-8" id="tel" type="tel" placeholder="Phone Number" name="Phone-Number" />
                                </div>

                                <div className='row' style={{marginTop: '1vh'}}>
                                    <textarea className="input-group-text col-8" rows="6" required placeholder="Message" name="Message"></textarea>
                                </div>
                                
                                <div class="col-8 text-center" style={{marginTop: '2vh'}}>
                                    <button className='btn btn-danger btn-lg' style={{marginRight: '1vw'}} type="reset">Reset</button>
                                    <button className='btn btn-primary btn-lg' disabled type="submit">Submit</button>
                                </div>
                                
                            </form>
                        </div>
                    </div>    
                    <div classNameName='col-4'>

                    </div>
                </div>
            </div>
        )
    }
}