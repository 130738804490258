import { Component } from "react";
import * as Icon from 'react-bootstrap-icons';
import './QuickFacts.css'

export default class QuickFacts_Spanish extends Component {
    render(){
        return(
            <div className='row main' style={{color: 'white'}}>
                <div className='col-4 my-4'>
                    <Icon.Bricks className='my-4' style={{fontSize: '3rem'}}/>
                    <p className='h4'>Jaquez Law maneja todo tipo de peticiones de inmigración, incluido el ajuste de estatus, acción diferida, ciudadanía y más.</p>
                </div>
                <div className='col-4 my-4'>
                    <Icon.ShieldShaded className='my-4' style={{fontSize: '3rem'}}/>
                    <p className='h4'>El abogado Jaquez se reunirá con usted o su familia para conocer más sobre su situación y poder determinar cuál es la mejor estrategia para defender sus derechos.</p>
                </div>
                <div className='col-4 my-4'>
                    <Icon.BriefcaseFill className='my-4' style={{fontSize: '3rem'}}/>
                    <p className='h4'>Me apasiona mucho defender los derechos de los inmigrantes y por eso les digo a todos mis clientes que les daré mi valoración honesta de su caso antes de comenzar cualquier trabajo para ustedes.</p>
                </div>
            </div>
        )
    }
}