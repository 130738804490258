import './TopNav.css'
import Logo from '../../Assets/jaquezlawofficelogo 4.svg'
import { Component } from 'react';

export default class TopNav_Spanish extends Component{

  render(){
    return (
      <div className='row' style={{height: '15vh', overflow: 'hidden', backgroundColor: '#03254e', color: '#fff'}}>
        <div className='col-4' style={{height: '15vh !important'}}>
          <img src={Logo} style={{height: '100%', width: '100%', objectFit: 'contain', position: 'absolute', top: '0px', left: '0px', transform: 'scale(1.5)'}} />
        </div>
        <div className='col-5'></div>
        <div className='col-3'>
          <div className='row' style={{height: '15vh', justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div className='col-3'>
                <h1 className='h6'><a href='#aboutme' style={{color: 'white'}}>Sobre mí</a></h1>
              </div>
              <div className='col-3'>
                <h1 className='h6'><a href='#aor' style={{color: 'white'}}>Servicios</a></h1>
              </div>
              <div className='col-3'>
                <h1 className='h6'><a href='#contact' style={{color: 'white'}}>Contacto</a></h1>
              </div>
              <div className='col-3'>
                <h1 className='h6'><a href='/' style={{color: 'white'}}>English</a></h1>
              </div>
            </div>
          </div>   
        </div> 
    );
  }
}