import { Component } from "react"
import profile from '../../Assets/jaquezLawProfile.jpg'

export default class Header_Spanish extends Component {
    render(){
        return(
            <div className='row' id="aboutme">
                {/* LEFT SIDE */}
                <div className='col-6'>
                    <div className='col-12' style={{}}>
                        <h1 className='h1 text-left' style={{padding: '2vh 0px', fontSize: 'calc((2.8 - 1) * 1.2vw + 1rem)'}}>Abogando por las inmigrantes desde 2008.</h1>
                    </div>
                    <div className='col-12'>
                        <p style={{fontSize: 'calc((1.1 - 1) * 1.2vw + 1rem)', textAlign: 'left'}}>El enfoque de mi práctica es el derecho de inmigración, derecho comercial y derecho penal. El abogado Luis Alberto Jaquez fue admitido en el Colegio de Abogados del Estado de California en diciembre de 2008. El abogado Jaquez es un feroz defensor de los derechos de los inmigrantes.</p>
                    </div>
                    <div className='col-12'>
                        <p style={{fontSize: 'calc((1.1 - 1) * 1.2vw + 1rem)', textAlign: 'left'}}>El abogado Jaquez ha manejado multitud de casos de defensa de deportación en los tribunales de inmigración de San Francisco y Los Ángeles. También es un experto en la presentación de peticiones de fianza de inmigración para ayudar a las familias a reunirse con sus seres queridos que han sido detenidos por el Servicio de Inmigración y Control de Aduanas (ICE). Todo tipo de peticiones de inmigración, incluido el ajuste de estatus, la acción diferida y la ciudadanía.</p>
                    </div>
                    <div className='col-12'>
                        <p style={{fontSize: 'calc((1.1 - 1) * 1.2vw + 1rem)', textAlign: 'left'}}>Mi objetivo es brindar el asesoramiento y el asesoramiento legales más confiables. Siento que la única forma de representar de manera justa a mis clientes es realizar un análisis completo de todos los hechos y circunstancias antes de decidir cómo manejar los casos de mi cliente. La situación de cada persona es diferente y presto atención a los detalles del caso de cada persona para encontrar el mejor resultado para mis clientes.</p>
                    </div>
                    <div className='col-12' style={{marginTop: '5vh'}}>
                        <div className='text-left'>
                            <button className='btn btn-outline-info btn-lg'><a href='#aor'>Aprende Más</a></button>
                        </div>
                    </div>
                </div>
                {/* RIGHT SIDE */}
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-6' style={{paddingLeft: '4vw'}}>
                        <img src={profile} style={{width: '70%', height: 'initial'}}/>
                        <p style={{fontSize: '1.25rem', padding: '1vh'}}>Luis Alberto Jaquez</p>
                        </div>
                    </div>
                    <div className='row' style={{marginTop: '10vh', marginLeft: '1vw'}}>
                        <p className='col-12 h3 text-left'><strong>Contacto</strong></p>
                        <p className='col-12 h6 text-left' style={{marginTop: '1vh'}}>879 W 190th Street, Suite 400</p>
                        <p className='col-12 h6 text-left'>Gardena, CA 90248</p>
                        <p className='col-12 h6 text-left' style={{marginTop: '1vh'}}>(424) 888-0529</p>
                    </div>
                </div>
            </div>
        )
    }
}