import { Component } from "react"
import profile from '../../Assets/jaquezLawProfile.jpg'

export default class MapMobile extends Component {
    render(){
        return(
            <div className='row'>
                <div className='col-12' style={{color: '#fff'}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.145912969077!2d-118.29137628460424!3d33.860130280658645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2caafc75769fb%3A0x6c8e43d7aa96737a!2s879%20W%20190th%20St%20Suite%20400%2C%20Gardena%2C%20CA%2090248!5e0!3m2!1sen!2sus!4v1611979106724!5m2!1sen!2sus" width="350" height="200" frameborder="0" style={{border: '0px'}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
                <div className='col-12 text-center'>
                    <h1 className='h5 pt-3'>879 W 190th St, Gardena, CA 90248</h1>
                </div>
            </div>
        )
    }
}