import { Component } from "react"
import profile from '../../Assets/jaquezLawProfile.jpg'

export default class Header extends Component {
    render(){
        return(
            <div className='row' id="aboutme">
                {/* LEFT SIDE */}
                <div className='col-6'>
                    <div className='col-12' style={{}}>
                        <h1 className='h1 text-left' style={{padding: '2vh 0px', fontSize: 'calc((2.8 - 1) * 1.2vw + 1rem)'}}>Advocating for immigrants since 2008.</h1>
                    </div>
                    <div className='col-12'>
                        <p style={{fontSize: 'calc((1.1 - 1) * 1.2vw + 1rem)', textAlign: 'left'}}>The focus of my practice is Immigration law, Business Law and Criminal Law.  Attorney Luis Alberto Jaquez was admitted to the State bar of California in December of 2008.  Attorney Jaquez is a fierce advocate for immigrants’ rights.</p>
                    </div>
                    <div className='col-12'>
                        <p style={{fontSize: 'calc((1.1 - 1) * 1.2vw + 1rem)', textAlign: 'left'}}>Attorney Jaquez has handled multitudes of Removal Defense cases in San Francisco and Los Angeles Immigration Courts.  He is also an expert at submitting Immigration Bond Petitions to help families become reunited with their loved ones that have been detained by Immigration and Customs Enforcement (ICE).  All types of Immigration Petitions including adjustment of status, Deferred Action and Citizenship.</p>
                    </div>
                    <div className='col-12'>
                        <p style={{fontSize: 'calc((1.1 - 1) * 1.2vw + 1rem)', textAlign: 'left'}}>My goal is to provide the most reliable legal advice and counsel.  I feel that the only way to fairly represent my clients is to conduct a complete analysis of all the facts and circumstances before deciding how to manage my client’s cases.  Every person’s situation is different and I pay attention to the details of each person’s case to find the best result for my clients.</p>
                    </div>
                    <div className='col-12' style={{marginTop: '5vh'}}>
                        <div className='text-left'>
                        <button className='btn btn-outline-info btn-lg'><a href='#aor'>Learn More</a></button>
                        </div>
                    </div>
                </div>
                {/* RIGHT SIDE */}
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-6' style={{paddingLeft: '4vw'}}>
                        <img src={profile} style={{width: '70%', height: 'initial'}}/>
                        <p style={{fontSize: '1.25rem', padding: '1vh'}}>Luis Alberto Jaquez</p>
                        </div>
                    </div>
                    <div className='row' style={{marginTop: '10vh', marginLeft: '1vw'}}>
                        <p className='col-12 h3 text-left'><strong>Contact Us</strong></p>
                        <p className='col-12 h6 text-left' style={{marginTop: '1vh'}}>879 W 190th Street, Suite 400</p>
                        <p className='col-12 h6 text-left'>Gardena, CA 90248</p>
                        <p className='col-12 h6 text-left' style={{marginTop: '1vh'}}>(424) 888-0529</p>
                    </div>
                </div>
            </div>
        )
    }
}