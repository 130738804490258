import { Component } from "react"
import profile from '../../Assets/jaquezLawProfile.jpg'

export default class AOR extends Component {
    render(){
        return(
            <div className='row' id="aor">
                <div className='col-10' style={{color: '#7E8080'}}>
                    <div className='row' style={{borderBottom: '2px solid #7E8080', width: '100%'}}>
                       <h1 className='h1 text-left' style={{padding: '2vh 2vw', fontSize: 'calc((2.8 - 1) * 1.2vw + 2.5rem)', color: '#7E8080'}}>Areas of Specialty</h1>
                    </div>
                    <div className='row' style={{borderBottom: '2px solid #7E8080', width: '100%'}}>
                       <div className='col-4'>
                           <h1 className='h3 text-left pt-5'>Family Petitions & Removal (Deportation) Defense</h1>
                       </div>
                       <div className='col-8 pl-5'>
                           <h1 className='h6 py-5 text-left' style={{lineHeight: '2rem'}}>If you have family here in the United States or in your home country outside of the United States, Attorney Jaquez can explain 
                           what is the best option to make a petition to obtain permanent residence and or citizenship for your family. When someone is detained by Immigration or given a notice to appear in Immigration court, Attorney Jaquez will meet with you and or 
                           your family to learn more about your situation so that he can determine what is the best strategy to defend your rights.</h1>
                       </div>
                    </div>
                    <div className='row' style={{borderBottom: '2px solid #7E8080', width: '100%'}}>
                       <div className='col-4'>
                           <h1 className='h3 text-left pt-5'>Business & Employment Visas</h1>
                       </div>
                       <div className='col-8 pl-5'>
                           <h1 className='h6 py-5 text-left' style={{lineHeight: '2rem'}}>If you or someone close to you has suffered because you were the victim of a crime then you may be eligible to apply for a U-Visa 
                           that can give you a work permit and eventually Permanent residence.Attorney Jaquez also works with many Businesses in the capacity as a business advisor to make important business decisions with 
                           confidence.</h1>
                       </div>
                    </div>
                    <div className='row' style={{borderBottom: '2px solid #7E8080', width: '100%'}}>
                       <div className='col-4'>
                           <h1 className='h3 text-left pt-5'>Possession and other Criminal Charges</h1>
                       </div>
                       <div className='col-8 pl-5'>
                           <h1 className='h6 py-5 text-left' style={{lineHeight: '2rem'}}>If you have been cited for a DUI, you must know that you do have options.  You have only 10 days to request a hearing from the DMV to 
                           contest the suspension of your license. If you have been arrested for any other criminal matter and want someone who will give you a complete and fair assessment of your case call Attorney Jaquez.</h1>
                       </div>
                    </div>
                </div>
            </div>
        )
    }
}