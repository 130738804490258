import { Component } from "react";
import * as Icon from 'react-bootstrap-icons';
import './QuickFacts.css'

export default class QuickFactsMobile extends Component {
    render(){
        return(
            <div className='row main' style={{color: 'white'}}>
                <div className='col-12 my-4'>
                    <Icon.Bricks className='my-4' style={{fontSize: '3rem'}}/>
                    <p className='h4 px-5'>Jaquez Law handles all types of Immigration Petitions including adjustment of status, Deferred Action, Citizenship and more.</p>
                </div>
                <div className='col-12 my-4'>
                    <Icon.ShieldShaded className='my-4' style={{fontSize: '3rem'}}/>
                    <p className='h4 px-5'>Attorney Jaquez will meet with you and or your family to learn more about your situation so that he can determine what is the best strategy to defend your rights.</p>
                </div>
                <div className='col-12 my-4'>
                    <Icon.BriefcaseFill className='my-4' style={{fontSize: '3rem'}}/>
                    <p className='h4 px-5'> I am very passionate about defending Immigrants’ rights and this is why I tell all my clients that I will give you my honest assessment of your case before beginning any work for you. </p>
                </div>
            </div>
        )
    }
}