import './App.css';
import homeBanner from './Assets/home_banner.jpg'
import profile from './Assets/jaquezLawProfile.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import {useSpring, animated} from 'react-spring'
import HomeCarousal from './Components/HomeCarousal/HomeCarousal'
import Header from './Components/Header/Header'
import HeaderMobile from './Components/Header/HeaderMobile'
import QuickFacts from './Components/QuickFacts/QuickFacts'
import QuickFactsMobile from './Components/QuickFacts/QuickFactsMobile'
import AOR from './Components/AOR/AOR'
import AORMobile from './Components/AOR/AORMobile'
import ContactUs from './Components/ContactUs/ContactUs'
import ContactUsMobile from './Components/ContactUs/ContactUsMobile'
import Map from './Components/Map/Map'
import MapMobile from './Components/Map/MapMobile'
import TopNavMobile from './Components/TopNav/TopNavMobile'
import TopNavMobile_Spanish from './Components/TopNav/TopNavMobile_Spanish'
import TopNav_Spanish from './Components/TopNav/TopNav_Spanish'

import TopNav from './Components/TopNav/TopNav'
import { Button } from 'bootstrap';
import { Component } from 'react';
import HomeCarousalMobile from './Components/HomeCarousal/HomeCarousalMobile';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import HeaderMobile_Spanish from './Components/Header/HeaderMobile_Spanish';
import Header_Spanish from './Components/Header/Header_Spanish';
import QuickFactsMobile_Spanish from './Components/QuickFacts/QuickFactsMobile_Spanish';
import QuickFacts_Spanish from './Components/QuickFacts/QuickFacts_Spanish';
import AOR_Spanish from './Components/AOR/AOR_Spanish';
import AORMobile_Spanish from './Components/AOR/AORMobile_Spanish';
import ContactUsMobile_Spanish from './Components/ContactUs/ContactUsMobile_Spanish';
import ContactUs_Spanish from './Components/ContactUs/ContactUs_Spanish';
 
const isMobile = window.innerWidth <= 768;

export default class App extends Component {
  
  componentDidMount(){
    document.title = "Jaquez Law"
  }

  render(){
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <div className="App">
              <title>Jaquez Law</title>
              <div className="App-header">
                {isMobile ? <TopNavMobile /> : <TopNav />}
                <Container fluid style={{padding: '0px 2vw'}}>
                  {isMobile ? <HomeCarousalMobile /> : <HomeCarousal />}
                </Container>
                <Container fluid style={{height: 'fit-content', paddingBottom: '10vh', marginTop: '5vh', paddingLeft: '5vw'}}>
                  {isMobile ? <HeaderMobile /> : <Header />}
                </Container>
                <Container fluid style={{height: 'fit-content', padding: '5vh 0px', marginTop: '0vh', paddingLeft: '0vw', backgroundColor: '#03254e'}}>
                  {isMobile ? <QuickFactsMobile /> : <QuickFacts />}
                </Container>
                <Container fluid style={{height: 'fit-content', paddingBottom: '15vh', paddingTop: '5vh', marginTop: '5vh', paddingLeft: '5vw'}}>
                  {isMobile ? <AORMobile /> : <AOR />}
                </Container>
                <Container fluid style={{height: 'fit-content', paddingBottom: '15vh', paddingTop: '5vh', marginTop: '5vh', backgroundColor: '#03254e'}}>
                  {isMobile ? <ContactUsMobile /> : <ContactUs />}
                </Container>
                <Container fluid style={{height: 'fit-content', paddingBottom: '15vh', paddingTop: '5vh', marginTop: '5vh',}}>
                  {isMobile ? <MapMobile /> : <Map />}
                </Container>
              </div>
              <footer style={{ paddingBottom: '2vh', paddingTop: '2vh', backgroundColor: '#03254e', color: '#fff'}}>Jaquez Law CC. 2021</footer>
            </div>
          </Route>
          <Route exact path="/:espanol">
            <div className="App">
                <title>Jaquez Law</title>
                <div className="App-header">
                  {isMobile ? <TopNavMobile_Spanish /> : <TopNav_Spanish />}
                  <Container fluid style={{padding: '0px 2vw'}}>
                    {isMobile ? <HomeCarousalMobile /> : <HomeCarousal />}
                  </Container>
                  <Container fluid style={{height: 'fit-content', paddingBottom: '10vh', marginTop: '5vh', paddingLeft: '5vw'}}>
                    {isMobile ? <HeaderMobile_Spanish /> : <Header_Spanish />}
                  </Container>
                  <Container fluid style={{height: 'fit-content', padding: '5vh 0px', marginTop: '0vh', paddingLeft: '0vw', backgroundColor: '#03254e'}}>
                    {isMobile ? <QuickFactsMobile_Spanish /> : <QuickFacts_Spanish />}
                  </Container>
                  <Container fluid style={{height: 'fit-content', paddingBottom: '15vh', paddingTop: '5vh', marginTop: '5vh', paddingLeft: '5vw'}}>
                    {isMobile ? <AORMobile_Spanish /> : <AOR_Spanish />}
                  </Container>
                  <Container fluid style={{height: 'fit-content', paddingBottom: '15vh', paddingTop: '5vh', marginTop: '5vh', backgroundColor: '#03254e'}}>
                    {isMobile ? <ContactUsMobile_Spanish /> : <ContactUs_Spanish />}
                  </Container>
                  <Container fluid style={{height: 'fit-content', paddingBottom: '15vh', paddingTop: '5vh', marginTop: '5vh',}}>
                    {isMobile ? <MapMobile /> : <Map />}
                  </Container>
                </div>
                <footer style={{ paddingBottom: '2vh', paddingTop: '2vh', backgroundColor: '#03254e', color: '#fff'}}>Jaquez Law CC. 2021</footer>
              </div>
          </Route>
        </Switch>
      </Router>
  
  
    );
  }
}
